import "./ContactUs.css"
import { Header, Footer } from "../../Sections"
import Map from "../../Assets/Images/Careers-contact-us.jpg"
import { useEffect, useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet-async"
const ContactUs = () => {
    const [full_name, setFull_Name] = useState()
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [phone, setPhone] = useState()
    const [company, setCompany] = useState()
    const [subject, setSubject] = useState()
    const [isSend, setIsSend] = useState(null)
    const { t, i18n } = useTranslation()
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsSend("loading")
        axios.post("https://api.al-jabriya.com/api/send_email/contact_us", {
            full_name, email, message, phone, company, subject
        })
            .then(res => {
                console.log(res)
                setIsSend(true)
                setTimeout(() => {
                    setIsSend(null)
                }, 2500)

            })
            .catch(err => {
                console.log(err)
                setIsSend(false)
            })
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <>
        <Helmet>
                 <title>Contact Us</title>
                <meta name="description" content="Get in touch with Al-jabriya for inquiries, support, or collaboration. Our team is ready to assist you with any questions or needs you may have." />
        </Helmet>
            <Header />

            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project link-career ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("contact")} </p>
            </div>
            <div className={`container-fluid career-con  ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-md-6 o-2">
                        <div className="Vacancies-form">
                            <h3>{t("contact")}</h3>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="mb-3">
                                    <label htmlFor="full-name" className="form-label">{t("FullName")}</label>
                                    <input type="text" id="full-name" className="form-control" required onChange={(e) => setFull_Name(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">{t("Email")}</label>
                                    <input type="email" id="email" className="form-control" required onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Phone" className="form-label">{t("Phone")} </label>
                                    <input type="text" id="Phone" className="form-control" required onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Company" className="form-label">{t("Company")} </label>
                                    <input type="text" id="Company" className="form-control" required onChange={(e) => setCompany(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Subject" className="form-label">{t("Subject")} </label>
                                    <input type="text" id="Subject" className="form-control" required onChange={(e) => setSubject(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Subject" className="form-label">{t("Message")} </label>
                                    <textarea className="form-control" onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>
                                {isSend == null ? <></> : isSend == true ?
                                    <div className="alert alert-success" role="alert">
                                        {t("Success")}
                                    </div> : <div className="alert alert-danger" role="alert">
                                        {t("faild")}
                                    </div>}
                                <div className="submit-btn">
                                    <button>{isSend == "loading" ? t("Sending...") : t("Send")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 o-1">
                        <div className="Vacancies-text">
                            <h3>{t("ContactAddress")}</h3>
                            <p className="location-map">{t("LocationMap")}</p>
                            <div className="contact-map-con">
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1738.4410611886062!2d47.9886933!3d29.3737405!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf8f920235c0c3%3A0x8f7b065d3b7a0e65!2sAl-Jabriya%20Furniture%20%26%20Interiors%20Company!5e0!3m2!1sde!2s!4v1706381099782!5m2!1sde!2s"
                                    width="600"
                                    height="450" style={{
                                        border: "0px"
                                    }}
                                    allowfullscreen=""
                                    className="goo"
                                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1446.7257000055595!2d47.98932621427983!3d29.3739414132246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf8596062ff023%3A0xa525a7e85b64410d!2sOula%20Tower!5e0!3m2!1sen!2skw!4v1721727398391!5m2!1sen!2skw"
                                    width="600"
                                    height="450" style={{
                                        border: "0px"
                                    }}
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <h6><span>Email: </span> info@al-jabriya.com</h6>
                            <h6><span>Tel: </span>+965 22945454</h6>
                            <h6><span>Address: </span>Kuwait city, Mirqab, Block 3, Omar Ben Alkhattab street, Oula Tower, Floor 9</h6>
                            <h6><span>P.O.Box: </span>24630 Safat 13107 Kuwait</h6>
                        </div>
                    </div>
                </div>

            </div>
            <Footer background="none" />
        </>
    )
}

export default ContactUs
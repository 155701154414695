import "./AboutUs.css"
import { AboutHero, Footer, FourDecade, Header, InternationalRecogition, Message, Mission, WhereDesign } from "../../Sections"
import { useEffect, useState } from "react"
import axios from "axios"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet-async"


const AboutUs = () => {
    <Helmet>
         <title>About us</title>
         <meta name="description" content="In 1977, Al-jabriya's journey began with Mr. Hassan Asfour, inspired by a brochure, shaping timeless style across generations." />
    </Helmet>

    const [team, setTeam] = useState()
    const [images, setImages] = useState()
    const { t, i18n } = useTranslation()
    useEffect(() => {
        axios.get("https://api.al-jabriya.com/api/our_team/all", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                console.log(res)
                setTeam(res.data.data.ourTeam)
            })
            .catch(err => {
                console.log(err)
            })
    }, [i18n.language])
    useEffect(() => {
        axios.get("https://api.al-jabriya.com/api/award/all", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                console.log(res)
                setImages(res.data.data.award)
            })
            .catch(err => {
                console.log(err)
            })
    }, [i18n.language])
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <>

            <Header page="sample" />
            <AboutHero />
            <WhereDesign />

            <FourDecade />

            <Message />

            <InternationalRecogition images={images} />

            <div className={`container-fluid our-team ${i18n.language == "ar" && "ar"}`}>
                <h2>{t("OurTeam")}</h2>
                <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                    {team && team.map(member => (
                        <div className="col">
                            <div className="card-team">
                                <img src={member.image} alt={`${member.name} image`} />
                                <h5>{member.sur_name}</h5>
                                <p>{member.name}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer background="all" />
        </>
    )
}

export default AboutUs
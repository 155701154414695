import "./Cx.css"
import { Header, Footer, CxHero, CxSlider } from "../../Sections"
import { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
const Cx = () => {
    const params = useParams()
    const { t, i18n } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const [product, setProduct] = useState()
    const sliderRef = useRef()
    const [cxId, setCxId] = useState(params.id)
    const [category, setCategory] = useState()
    const [previous, setPrevious] = useState()
    const [initialSlide, setInititalSlide] = useState(0)
    const [next, setNext] = useState()

    useEffect(() => {
        axios.post("https://api.al-jabriya.com/api/show/product", {
            product_uuid: cxId
        }, {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                setProduct(res.data.data.product)
                setCategory(res.data.data.category)
                axios.post("https://api.al-jabriya.com/api/product/adjacent", {
                    product_uuid: res.data.data.product.uuid,
                    category_brand_uuid: location.state.categoryBrandUuid != undefined ? location.state.categoryBrandUuid : null,
                    brand_uuid: location.state.brand_uuid != undefined ? location.state.brand_uuid : null
                }, {
                    headers: {
                        "Accept-Language": i18n.language
                    }
                })
                    .then(res => {
                        console.log(res)
                        setNext(res.data.data.next_product)
                        setPrevious(res.data.data.previous_product)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
            .catch(err => {
                console.log(err)
            })
        setInititalSlide(0)
    }, [cxId, i18n.language])
    const handleNext = () => {
        setCxId(next.uuid)
        setInititalSlide(0)
        sliderRef.current.swiper.slideTo(0);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    const handlePrevious = () => {
        setCxId(previous.uuid)
        setInititalSlide(0)
        sliderRef.current.swiper.slideTo(0);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    const handleNavigate = () => {
        navigate("/OfficeDesks", {
            state: {
                logoID: location.state.link,
                title: category && category
            }
        })
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        setInititalSlide(0)
    }, [])
    return (
        <>
        <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="" />
                <link rel="canonical" href="/" />
        </Helmet>
            <Header page="sample" />

            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / <Link to="/products">{t("products")}</Link> /<span onClick={handleNavigate}> {category && category} </span>/ {product && product.title} </p>
            </div>
            <div className={`container-fluid cx-hero px-0 ${i18n.language == "ar" && "ar"}`}>
                <div className="row row-m-0">
                    <div className="col-md-6 col-img">
                        <div className="img"
                            style={{
                                backgroundImage: `url(${product && product.image})`
                            }}>
                            {/* <img src={product && product.image} /> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="text">
                            <h2>{product && product.title}</h2>
                            <p>Supplier: {product && product.supplier}<br />
                                Country of Origin: {product && product.country}</p>
                            <h6>{product && product.description}</h6>
                        </div>
                    </div>
                </div>
            </div>
            {product && product.gallery.length > 0 ?
                <div className={`cx-slider rrr container-fluid px-0 ${i18n.language == "ar" && "ar"}`}>
                    <div className="next" onClick={() => sliderRef.current.swiper.slideNext()}>
                        <svg
                            style={{
                                cursor: "pointer"
                            }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                            <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                        </svg>


                    </div>
                    <div className="prev" onClick={() => sliderRef.current.swiper.slidePrev()}>
                        <svg
                            style={{
                                cursor: "pointer"
                            }}
                            xmlns="http://www.w3.org/2000/svg" width="41" height="77" viewBox="0 0 41 77" fill="none">
                            <path d="M39.5 0.936768L2 38.4368L39.5 75.9368" stroke="#434485" strokeWidth="2" />
                        </svg>
                    </div>
                    <Swiper
                        key={cxId}
                        ref={sliderRef}
                        modules={[Pagination]}
                        pagination={{ clickable: true }}
                        spaceBetween={50}
                        slidesPerView={1}
                        initialSlide={initialSlide}
                        loop={true}
                    >
                        {product && product.gallery.map(item => (
                            <SwiperSlide>
                                <div className="slide-img" style={{
                                    backgroundImage: `url(${item})`
                                }}></div>
                            </SwiperSlide>
                        ))}
                        {/* <SwiperSlide>
                        <div className="slide-img"></div>
                    </SwiperSlide>
                    <SwiperSlide> <div className="slide-img"></div></SwiperSlide>
                    <SwiperSlide> <div className="slide-img"></div></SwiperSlide> */}

                    </Swiper>
                </div> : <></>}

            <div className={`container-fluid cx-next ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-6 prev-cx">
                        {previous == undefined ? <></> :
                            <div className="flex-aswad-center" onClick={handlePrevious} style={{
                                cursor: "pointer"
                            }}>
                                <svg

                                    style={{
                                        cursor: "pointer"
                                    }}
                                    xmlns="http://www.w3.org/2000/svg" width="41" height="77" viewBox="0 0 41 77" fill="none">
                                    <path d="M39.5 0.936768L2 38.4368L39.5 75.9368" stroke="#434485" strokeWidth="2" />
                                </svg>
                                <div className="text text-prev">
                                    <h4>{previous && previous.title}</h4>
                                    <p>{t("PreviousProduct")}</p>
                                </div>
                                <div className="img-next img-prev" style={{
                                    backgroundImage: `url(${previous && previous.image})`
                                }}></div>

                            </div>}
                    </div>
                    <div className="col-6 cx-prev">
                        {next == undefined ? <></> :
                            <div className="flex-aswad-center" onClick={handleNext} style={{
                                cursor: "pointer"
                            }}>
                                <div className="img-next" style={{
                                    backgroundImage: `url(${next && next.image})`
                                }}></div>
                                <div className="text">
                                    <h4>{next && next.title}</h4>
                                    <p>{t("NextProduct")}</p>
                                </div>
                                <svg

                                    style={{
                                        cursor: "pointer"
                                    }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                    <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                                </svg>
                            </div>}


                    </div>
                </div>
            </div>
            <Footer background="none" />
        </>
    )
}

export default Cx
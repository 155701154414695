import "./Design.css"
import { Header, Footer, BringingGreat, CaseStudy, SpacePlanning, DesignJabyriah } from "../../Sections"
import { useEffect, useState } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet-async"
const Design = () => {
    const [caseStudy, setCaseStudy] = useState()
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    useEffect(() => {
        axios.get("https://api.al-jabriya.com/api/home", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {

                console.log(res)
                setCaseStudy(res.data.data.caseStudent)
            })
            .catch(err => {

                console.log(err)
            })
    }, [i18n.language])
    const handleNavigate = () => {
        navigate("/NewCaseStudy", {
            state: {
                id: caseStudy.uuid
            }
        })
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <>
            <Helmet>
                <title>Design</title>
                <meta name="description" content="We meticulously plan spaces to be aesthetically pleasing and functional, reflecting clients' unique vision and style. 'We design what clients need, not just what they want." />
            </Helmet>

            <Header page="sample" />
            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("design")}</p>
            </div>
            <div className="BringingGreat-con-gre">
                <BringingGreat />
            </div>

            <div className="BringingGreat-con-gre">
                <SpacePlanning />
            </div>

            {/* <div className={`container-fluid case-study-sec ${i18n.language == "ar" && "ar h"}`}>
                <div className="row m-0 px-0">
                    <div className="col-md-7 case-img px-0 m-0" style={{
                        backgroundImage: `url(${caseStudy && caseStudy.image})`
                    }}>

                    </div>
                    <div className="col-md-5 flex-aswad-center case-text">
                        <div className="text-arrow" onClick={handleNavigate}>
                            <div className="text">
                                <h2>{t("casestudyHome")}</h2>
                                <p>{caseStudy && caseStudy.title}</p>
                            </div>

                            <svg

                                style={{
                                    cursor: "pointer",
                                    marginBottom: "10px"
                                }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div> */}

            <DesignJabyriah />

            <Footer background="all" />
        </>
    )
}

export default Design
import "./Fitout.css"
import { Header, Footer } from "../../Sections"
import { Link, useNavigate } from "react-router-dom"
import { useAnimation, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet-async"

const Fitout = () => {
    var hugeScreen = window.matchMedia("(min-width:3025px)")
    const navigate = useNavigate()
    const animation = useAnimation()
    const { t, i18n } = useTranslation()
    useEffect(() => {
        animation.start({
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                duration: 1
            }
        })
    }, [])
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    const handleNavigate = () => {
        navigate("/projects", {
            state: {
                uuid: "9109a677-a7cd-41f2-9cfd-7bd39c6a8955",
                title: "Turnkey"
            }
        })
    }

    return (
        <>
        <Helmet>
                <title>Fitout</title>
                <meta name="description" content="Our high-end fit-out department delivers top-quality interior transformations, including shop drawings, MEP services, and comprehensive fit-out solutions, from concept to completion." />
        </Helmet>

            <Header page="sample" />

            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("fitout")}</p>
            </div>
            <div className="BringingGreat-con-gre">
                <div id="fitout" className={`bringing-great fitout ${i18n.language == "ar" && "ar"}`}>
                    <motion.div
                        initial={{
                            y: 1000,
                            opacity: 0
                        }}
                        animate={animation}
                        className="blur-animation"></motion.div>
                    <h2>{t("MakingYourVision")}</h2>
                    <p>{t("MakingYourVisionDesc")}</p>
                </div>
            </div>
            <div className="BringingGreat-con-gre">
                <div className={`container-fluid space-planning-con ${i18n.language == "ar" && "ar"}`}>
                    <div className="row row-cols-2 ">
                        <div className="col-md-6">
                            <div className="space-planning-col">
                                <h2>{t("ShopDrawings")}</h2>
                                <p>{t("ShopDrawingsDesc")}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="space-planning-col">
                                <h2>{t("MEPServices")}</h2>
                                <p>{t("MEPServicesDesc")}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="space-planning-col">
                                <h2>{t("InteriorFit-out")}</h2>
                                <p>{t("InteriorFit-outDesc")}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="space-planning-col m-last">
                                <h2>{t("ProjectManagementF")}</h2>
                                <p>{t("ProjectManagementFDesc")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`container-fluid design-jabyriah ${i18n.language == "ar" && "ar"}`}>
                <div className="row d-xll">
                    <div className="col-md-6">
                        <div className="text" onClick={handleNavigate} style={{
                            cursor: "pointer"
                        }}>
                            <h2>{t("ExplorealJabriya")}   <svg

                                style={{
                                    cursor: "pointer"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="38" height="68" viewBox="0 0 38 68" fill="none">
                                <path d="M1.5 67L36.5 34L1.5 0.999996" stroke="#434485" strokeWidth="2" />
                            </svg></h2>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>
            </div>
            <Footer background="all" />
        </>
    )
}

export default Fitout
import "./Home.css"
import { About, Footer, Header, Hero } from "../../Sections"
import { Loading } from "../../Components"
import { useEffect, useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useAnimation, motion } from "framer-motion"
import { useDispatch } from "react-redux"
import { pageName } from "../../redux/DetailsSlice"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
const Home = () => {
    const { t, i18n } = useTranslation()
    const [caseStudy, setCaseStudy] = useState()
    const [featuredProject, setFeaturedProject] = useState()
    const [newProduct, setNewProduct] = useState()
    const [loading, setLoading] = useState(false)
    const caseStudyAnimation = useAnimation()
    const [email, setEmail] = useState()
    const [status, setStatus] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => {
        window.sessionStorage.setItem("modal", false)
        setShow(true)
    };
    const navigate = useNavigate()
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(pageName("home"))
        caseStudyAnimation.start({
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                delay: 2
            }
        })
        axios.get("https://api.al-jabriya.com/api/home", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                // console.log(res)
                setCaseStudy(res.data.data.caseStudent)
                setFeaturedProject(res.data.data.project)
                setNewProduct(res.data.data.latestProduct)
            })
            .catch(err => {
                console.log(err)
            })
    }, [i18n.language])
    const handleNavigate = () => {
        navigate(`/CaseStudy/${caseStudy.uuid}`, {
            state: {
                id: caseStudy.uuid
            }
        })
    }
    const handleProjects = () => {
        navigate(`/Project/${featuredProject.uuid}`, {
            state: {
                id: featuredProject.uuid
            }
        })
    }
    const handleProduct = () => {
        navigate(`/product/${newProduct.uuid}`, {
            state: {
                id: newProduct.uuid,

            }
        })
    }
    useEffect(() => {
        setTimeout(() => {
            if (window.sessionStorage.getItem("modal") == "false") {
                handleClose()
            }
            else {
                handleShow()
            }

        }, 200000)
    }, [])

    const handleSubscribtion = () => {
        axios.post("https://api.al-jabriya.com/api/add/subscribe", {
            email
        })
            .then(res => {
                console.log(res)
                setStatus(true)
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <>
        <Helmet>
            <title>Home</title>
            <meta name="description" content="In 1977, Al-jabriya's journey began with Mr. Hassan Asfour, inspired by a brochure, shaping timeless style across generations." />
        </Helmet>
            {/* {loading == true ? <Loading /> : <></>} */}
            <Modal
                className={`${i18n.language == "ar" && "ar modal-ar"}`}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-9">
                            <div className="text">
                                {status == false ? <h3>{t("Subscribe")}</h3> : <h3>{t("Thank")}</h3>}
                                {status == false ? <div className="Subscribe-input">
                                    <input type="text" placeholder={t("E-Mail")} onChange={(e) => setEmail(e.target.value)} />
                                    <button onClick={handleSubscribtion}>{t("SubscribeBtn")}</button>
                                </div> : <></>}

                            </div>
                        </div>
                        <div className="col-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124" viewBox="0 0 124 124" fill="none">
                                <path d="M123.999 0.00683594H0.0429688V123.803H123.999V0.00683594Z" fill="#464380" />
                                <path d="M58.5752 76.5109H65.4749V79.3001C65.4749 86.9948 71.7421 93.2621 79.455 93.2621C87.1679 93.2621 93.435 87.003 93.435 79.3001V30.5314H85.64V23.6406H100.327V79.3001C100.327 90.8011 90.9627 100.153 79.455 100.153C67.9473 100.153 58.5752 90.8011 58.5752 79.3001V76.5109Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Header page="home" />
            <Hero word={t("WhereDesign")} />
            <motion.div
                initial={{
                    y: 100,
                    opacity: 0
                }}
                animate={caseStudyAnimation}
                className={`container-fluid case-study-sec ${i18n.language == "ar" && "ar h"}`}>
                <div className="row m-0 px-0">
                    <div className="col-md-7 case-img px-0 m-0 test-img"
                        style={{
                            backgroundImage: `url(${caseStudy && caseStudy.image})`
                        }}
                    >
                    </div>
                    <div className="col-md-5 flex-aswad-center case-text">
                        <div className="text-arrow" onClick={handleNavigate}>
                            <div className="text">
                                <h2>{caseStudy && caseStudy.title}</h2>
                                <p>{t("casestudyHome")}</p>
                            </div>
                            <svg

                                style={{
                                    cursor: "pointer",
                                    marginBottom: "10px"
                                }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                            </svg>
                        </div>
                    </div>
                </div>
            </motion.div>
            <div className={`container-fluid   featured-project ${i18n.language == "ar" && "ar h"}`}>
                <div className="row">
                    <div className="col-md-5 flex-aswad-center case-text">
                        <div className="text-arrow" onClick={handleProjects}>
                            <div className="text">
                                <h2>
                                    {featuredProject && featuredProject.title}
                                </h2>
                                <p> {t("FeaturedProject")}</p>
                            </div>
                            <svg
                                style={{
                                    cursor: "pointer",
                                    marginBottom: "10px"
                                }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                            </svg>
                        </div>
                    </div>
                    <div className="col-md-7 w px-0 w-xl-img" style={{
                        backgroundImage: `url(${featuredProject && featuredProject.image})`
                    }}>

                    </div>
                </div>

            </div>

            <div className={`container-fluid  featured-project new-product ${i18n.language == "ar" && "ar h"}`}>
                <div className="row px-0 m-0">
                    <div className="col-md-7 case-img px-0 w"
                        style={{
                            backgroundImage: `url(${newProduct && newProduct.image})`
                        }}>
                    </div>
                    <div className="col-md-5 flex-aswad-center">
                        <div className="text-arrow" onClick={handleProduct}>
                            <div className="text">
                                <h2>{newProduct && newProduct.title}</h2>
                                <p>{t("NewProduct")}</p>
                            </div>
                            <svg

                                style={{
                                    cursor: "pointer",
                                    marginBottom: "10px"
                                }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                            </svg>

                        </div>
                    </div>

                </div>

            </div>

            <About />

            <Footer />

        </>
    )
}

export default Home
import "./OfficeDesks.css"
import { Header, Footer } from "../../Sections"
import Img from "../../Assets/Images/1.png"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { Loading } from "../../Components"
import axios from "axios"
import { Modal } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
const OfficeDesks = () => {
    const location = useLocation()
    const [products, setProducts] = useState()
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()
    useEffect(() => {
        axios.post("https://api.al-jabriya.com/api/product/all", {
            category_brand_uuid: location.state ? location.state.brandId : null,
            brand_uuid: location.state ? location.state.logoID : null
        })
            .then(res => {
                setProducts(res.data.data.products)
                setLoading(false)
            })
            .catch(err => {
                setLoading(true)
                console.log(err)
            })
    }, [])
    const handleNavigate = (ID, productID) => {
        navigate("/cx", {
            state: {
                id: ID,
                brandTitle: location.state.title,
                productID: productID,
                categoryBrandUuid: location.state ? location.state.brandId : null,
                link: location.state.brandId,
                brand_uuid: location.state ? location.state.logoID : null
            }

        })
    }
    return (
        <>
        <Helmet>
                 <title>Office Desk</title>
                <meta name="description" content="Explore Al-jabriya's range of office desks, designed to combine style and functionality, enhancing productivity and creating inspiring workspaces." />
        </Helmet>
            <Header page="sample" />

            <div className="link-project-con">
                <p className={"link link-project"}><Link to="/">Home</Link>  /<Link to="/brands">Brands</Link>  / {location.state.title} </p>
            </div>

            <div className="container-fluid office-desks">
                {loading == true ? <Loading /> : <></>}
                <h2>{location.state.title}</h2>
                <div className="row row-cols-2  row-cols-md-3  ">
                    {products && products.map(product => (
                        <div className="col">
                            <div className="office-desks-card" onClick={() => handleNavigate(product.uuid, product.title)}>
                                <div className="img-brands-imges"
                                    style={{
                                        backgroundImage: `url(${product.image})`
                                    }}></div>
                                <p>{product.title}</p>
                            </div>
                        </div>
                    ))}
                    {/* <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="office-desks-card">
                            <img src={Img} />
                            <p>CX</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer background="none" />
        </>
    )
}

export default OfficeDesks
import "./CaseStudyDetails.css"
import { Header, Footer, CxSlider } from "../../Sections"
import Img from "../../Assets/Images/16.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
const CaseStudyDetails = () => {
    const sliderRef = useRef()
    const [details, setDetails] = useState()
    const location = useLocation()
    const [caseStudyId, setCaseStudyId] = useState(location.state.id)
    const [previous, setPrevious] = useState()
    const [next, setNext] = useState()

    useEffect(() => {
        axios.post("https://api.al-jabriya.com/api/show/case_student", {
            caseStudent_uuid: caseStudyId
        })
            .then(res => {
                setDetails(res.data.data.caseStudent)
                axios.post("https://api.al-jabriya.com/api/case_student/adjacent", {
                    caseStudent_uuid: caseStudyId
                })
                    .then(res => {
                        console.log(res)
                        setNext(res.data.data.next_caseStudent)
                        setPrevious(res.data.data.previous_caseStudent)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
            .catch(err => {
                console.log(err)
            })
    }, [caseStudyId])
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    const handleNext = () => {
        setCaseStudyId(next.uuid)
    }
    const handlePrevious = () => {
        setCaseStudyId(previous.uuid)
    }
    return (
        <>
            <Helmet>
                <title>case study</title>
                <meta name="description" content= {details && details.challenge} />
            </Helmet>
            <Header page="sample" />

            <div className="link-project-con link-LFHO">
                <p className={"link link-project link-LFHO"}><Link to="/">Home</Link>  /  <Link to="/Casestudy"> Casestudy </Link>  /  {details && details.title}</p>
            </div>

            <div className="container-fluid con-sm-case p-0 details">
                <div className="container-fluid LFHO">
                    <div className="row">
                        <div className="col-md-6 col-img-LFHO" style={{
                            backgroundImage: `url(${details && details.image})`
                        }}>

                        </div>
                        <div className="col-md-6 col-img-text">
                            <div className="text">
                                <h2>{details && details.title}</h2>
                                <h3>{details && details.partial_description}</h3>
                                <p>
                                    {details && details.description}
                                </p>
                                {/* <div className="read-more-LFHO">
                                    <h6>Read More</h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="87" viewBox="0 0 47 87" fill="none">
                                        <path d="M1 86L46 43.5L1 1" stroke="#CCCCCC" />
                                    </svg>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <hr />
            <div className="container-fluid Challenges">
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-6  o-2">
                        <div className="text">
                            <h2>Challenges</h2>
                            <p>
                                {details && details.challenge}
                            </p>
                            <h2>Solutions</h2>
                            <p>{details && details.solution}</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-6 o-1 col-img-challenges"
                    >
                        <div className="details-img "
                            style={{
                                backgroundImage: `url(${details && details.image})`
                            }}></div>
                    </div>
                </div>
            </div>
            {details && details.gallery.length > 0 ?
                <div className="container-fluid Results">
                    <h2>Results</h2>
                    <div className="cx-slider container px-0">
                        <div className="next" onClick={() => sliderRef.current.swiper.slideNext()}>
                            <svg
                                style={{
                                    cursor: "pointer"
                                }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                            </svg>
                        </div>
                        <div className="prev" onClick={() => sliderRef.current.swiper.slidePrev()}>
                            <svg
                                style={{
                                    cursor: "pointer"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="41" height="77" viewBox="0 0 41 77" fill="none">
                                <path d="M39.5 0.936768L2 38.4368L39.5 75.9368" stroke="#434485" strokeWidth="2" />
                            </svg>
                        </div>
                        <Swiper
                            ref={sliderRef}
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            spaceBetween={50}
                            slidesPerView={1}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {details && details.gallery.length > 0 && details.gallery.map(item => (
                                <SwiperSlide>
                                    <div className="slide-img"
                                        style={{
                                            backgroundImage: `url(${item})`
                                        }}></div>
                                </SwiperSlide>
                            ))}
                            {/* <SwiperSlide>
                            <div className="slide-img"></div>
                        </SwiperSlide>
                        <SwiperSlide> <div className="slide-img"></div></SwiperSlide>
                        <SwiperSlide> <div className="slide-img"></div></SwiperSlide> */}

                        </Swiper>
                    </div>
                </div> : <></>}

            <div className="container-fluid cx-next">
                <div className="row">
                    <div className="col-6 prev-cx ">
                        {previous == undefined ? <></> : <div className="flex-aswad-center">

                            <svg
                                onClick={handlePrevious}
                                style={{
                                    cursor: "pointer"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="41" height="77" viewBox="0 0 41 77" fill="none">
                                <path d="M39.5 0.936768L2 38.4368L39.5 75.9368" stroke="#434485" strokeWidth="2" />
                            </svg>
                            <div className="text text-prev">
                                <h4>Previous CaseStudy</h4>
                                <p>{previous && previous.title}</p>
                            </div>
                            <div className="img-next img-prev" style={{
                                backgroundImage: `url(${previous && previous.image})`
                            }}></div>
                            {/* 
                            <img src={previous && previous.image} /> */}
                        </div>}
                    </div>
                    <div className="col-6 cx-prev ">
                        {next == undefined ? <></> : <div className="flex-aswad-center">
                            <div className="img-next" style={{
                                backgroundImage: `url(${next && next.image})`
                            }}></div>
                            {/* <img src={next && next.image} /> */}
                            <div className="text">
                                <h4>Next CaseStudy</h4>
                                <p>{next && next.title}</p>
                            </div>
                            <svg
                                onClick={handleNext}
                                style={{
                                    cursor: "pointer"
                                }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                            </svg>
                        </div>}


                    </div>
                </div>
            </div>

            <Footer background="none" />
        </>
    )
}

export default CaseStudyDetails